var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, ref, inject, onBeforeMount } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import Execution from '../../../commonvue/src/components/Execution/Execution.vue';
import { IonButton, IonPage, IonButtons, IonHeader, IonToolbar, IonTitle, IonContent, } from '@ionic/vue';
import { VideoRecorder, VideoRecorderCamera, } from '@teamhive/capacitor-video-recorder';
import { ScreenRecorder, } from '@corban93/capacitor-screen-recorder';
import TestExecutionHeader from '@/components/TestExecutionHeader.vue';
import watStore from '@/store/watux.store';
import { AppVersion } from '@awesome-cordova-plugins/app-version';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { requestPermissions } from '../../../commonvue/src/utils/MobilePermissions';
import Swal from 'sweetalert2';
import Bugsnag from '@bugsnag/js';
import authStore from '@/store/auth.store';
export default defineComponent({
    name: 'Test',
    emits: ['rechargeTests'],
    props: {
        id: {
            type: String,
            default: null,
        },
    },
    components: {
        IonButton: IonButton,
        IonPage: IonPage,
        IonButtons: IonButtons,
        IonHeader: IonHeader,
        IonToolbar: IonToolbar,
        IonTitle: IonTitle,
        IonContent: IonContent,
        Execution: Execution,
        TestExecutionHeader: TestExecutionHeader,
    },
    data: function () {
        return {
            platform: '',
            appversion: '',
            isAppNative: false,
            isAndroid: false
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.registerWatUXEventListeners();
                this.isAppNative = Capacitor.isNativePlatform();
                this.isAndroid = Capacitor.getPlatform() === 'android';
                if (Capacitor.isNativePlatform()) {
                    AppVersion.getVersionNumber().then(function (appversion) {
                        _this.appversion = appversion;
                    });
                }
                return [2 /*return*/];
            });
        });
    },
    methods: {
        bugsnagInfo: function (step) {
            var user = authStore.getUserData();
            Bugsnag.notify(step.detail + ' - ' + user.identifier, function (event) {
                event.severity = 'info';
                event.context = 'Wat Ux Logs - ' + user.identifier;
                event.setUser(user.identifier, '', '');
            });
        },
        requestPermissionsMobile: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var permissionGranted, event_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, requestPermissions(event.detail.devices, this.platform)];
                        case 1:
                            permissionGranted = _a.sent();
                            if (permissionGranted == true) {
                                this.bugsnagInfo({ detail: 'Permissions granted' });
                                event_1 = new CustomEvent('watux-permissions-granted');
                                window.dispatchEvent(event_1);
                            }
                            else {
                                this.bugsnagInfo({ detail: 'Permissions denied' });
                                this.permissionsWarning();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Setup
        registerWatUXEventListeners: function () {
            var _this = this;
            window.addEventListener('watux-request-permissions', function (event) {
                return _this.requestPermissionsMobile(event);
            });
            window.addEventListener('watux-recording', function (event) {
                return _this.handleWatUXRecordingEvent(event);
            });
            window.addEventListener('watux-checkpoint-completion', function (event) {
                return _this.handleWatUXCheckpointCompletionEvent(event);
            });
            window.addEventListener('watux-notify-record-insertion', function (event) {
                return _this.handleWatUXNotifyRecordInsertionEvent(event);
            });
            window.addEventListener('watux-data-validation', function (event) {
                return _this.handleWatUXDataValidationEvent(event);
            });
            window.addEventListener('watux-bugsnag-event', function (event) {
                return _this.bugsnagInfo(event);
            });
        },
        registerCapacitorAppListeners: function (active) {
            var _this = this;
            if (active) {
                App.removeAllListeners();
                App.addListener('appStateChange', function (_a) {
                    var isActive = _a.isActive;
                    _this.handleAppActiveState(isActive);
                });
            }
        },
        // Event methods
        handleWatUXRecordingEvent: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.platform = event.detail.platform;
                    this.bugsnagInfo({ detail: 'Recording event: ' + event.detail.action });
                    switch (event.detail.action) {
                        case 'prepare':
                            this.blankVideoRecorderInitialize();
                            break;
                        case 'start':
                            this.startRecording(event.detail.devices, event.detail.initialized);
                            break;
                        case 'stop':
                            this.stopRecording(event.detail.devices);
                            break;
                    }
                    return [2 /*return*/];
                });
            });
        },
        handleWatUXCheckpointCompletionEvent: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    data = event.detail;
                    watStore.notifyCompletion(data).catch(function (error) {
                        console.log(error);
                    });
                    return [2 /*return*/];
                });
            });
        },
        handleWatUXNotifyRecordInsertionEvent: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var data, vm;
                var _this = this;
                return __generator(this, function (_a) {
                    data = event.detail;
                    vm = this;
                    watStore
                        .notifyCompletion(data)
                        .then(function (response) {
                        var event = new CustomEvent('watux-notify-record-insertion-complete', {
                            detail: {
                                platform: _this.platform,
                                response: response,
                            },
                        });
                        window.dispatchEvent(event);
                    })
                        .catch(function (error) {
                        _this.errorRecordingWarning(vm);
                    });
                    return [2 /*return*/];
                });
            });
        },
        closeExecution: function () {
            var execution = this.$refs.execution;
            execution.exitExecution();
        },
        handleWatUXDataValidationEvent: function (event) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                var _this = this;
                return __generator(this, function (_a) {
                    data = event.detail;
                    watStore
                        .validateDataCompletion(data)
                        .then(function (response) {
                        var event = new CustomEvent('watux-data-validation-complete', {
                            detail: {
                                platform: _this.platform,
                                response: response,
                            },
                        });
                        window.dispatchEvent(event);
                    })
                        .catch(function (error) {
                        console.log(error);
                    });
                    return [2 /*return*/];
                });
            });
        },
        handleAppActiveState: function (active) {
            return __awaiter(this, void 0, void 0, function () {
                var promises, _a, _b, _c, _d, _e, _f, event;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            if (!!active) return [3 /*break*/, 4];
                            promises = [];
                            _b = (_a = promises).push;
                            return [4 /*yield*/, this.stopVideoRecord()];
                        case 1:
                            _b.apply(_a, [_g.sent()]);
                            _d = (_c = promises).push;
                            return [4 /*yield*/, this.destroyVideoRecorder()];
                        case 2:
                            _d.apply(_c, [_g.sent()]);
                            _f = (_e = promises).push;
                            return [4 /*yield*/, this.stopScreenRecord()];
                        case 3:
                            _f.apply(_e, [_g.sent()]);
                            Promise.allSettled(promises).then(function (results) {
                                console.log('grabacion parada por background');
                            });
                            _g.label = 4;
                        case 4:
                            if (active) {
                                App.removeAllListeners();
                                event = new CustomEvent('watux-background-recovered', {
                                    detail: {
                                        platform: this.platform,
                                        active: active,
                                    },
                                });
                                window.dispatchEvent(event);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Capacitor recording methods
        startRecording: function (devices, initialized) {
            return __awaiter(this, void 0, void 0, function () {
                var promises, _a, _b, _c, _d, _e, _f, vm;
                var _this = this;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            promises = [];
                            if (!devices.screen) return [3 /*break*/, 2];
                            _b = (_a = promises).push;
                            return [4 /*yield*/, this.startScreenRecord()];
                        case 1:
                            _b.apply(_a, [_g.sent()]);
                            _g.label = 2;
                        case 2:
                            if (!devices.camera) return [3 /*break*/, 6];
                            if (!initialized) return [3 /*break*/, 4];
                            _d = (_c = promises).push;
                            return [4 /*yield*/, this.startVideoRecord()];
                        case 3:
                            _d.apply(_c, [_g.sent()]);
                            return [3 /*break*/, 6];
                        case 4:
                            _f = (_e = promises).push;
                            return [4 /*yield*/, this.initializeVideoRecorder()];
                        case 5:
                            _f.apply(_e, [_g.sent()]);
                            _g.label = 6;
                        case 6:
                            vm = this;
                            Promise.allSettled(promises)
                                .then(function (results) {
                                console.log('Grabacion iniciada');
                                vm.bugsnagInfo({ detail: 'Recording STARTED before promise' });
                                App.addListener('appStateChange', function (_a) {
                                    var isActive = _a.isActive;
                                    _this.registerCapacitorAppListeners(isActive);
                                });
                            })
                                .catch(function (error) {
                                vm.bugsnagInfo({ detail: 'Recording FAILED before promise ' + error });
                                console.error(error);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        stopRecording: function (devices) {
            return __awaiter(this, void 0, void 0, function () {
                var vm, promises, isRecording, isRecordingData, error_1, _a, _b, _c, _d;
                var _this = this;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            vm = this;
                            promises = [];
                            isRecording = true;
                            _e.label = 1;
                        case 1:
                            _e.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, ScreenRecorder.isRecording()];
                        case 2:
                            isRecordingData = _e.sent();
                            isRecording = isRecordingData.isRecording;
                            vm.bugsnagInfo({ detail: 'Finish record, variable isRecording state = ' + isRecording });
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _e.sent();
                            vm.bugsnagInfo({ detail: 'Finish record, failed isRecording state' });
                            throw error_1;
                        case 4:
                            console.log(isRecording);
                            if (!devices.screen || !devices.camera || !isRecording) {
                                console.log('errorRecordingWarning');
                                vm.bugsnagInfo({ detail: 'Error recording warning, show modal' });
                                return [2 /*return*/, this.errorRecordingWarning(vm)];
                            }
                            _b = (_a = promises).push;
                            return [4 /*yield*/, this.stopScreenRecord()];
                        case 5:
                            _b.apply(_a, [_e.sent()]);
                            _d = (_c = promises).push;
                            return [4 /*yield*/, this.stopVideoRecord()];
                        case 6:
                            _d.apply(_c, [_e.sent()]);
                            Promise.allSettled(promises)
                                .then(function (results) {
                                vm.bugsnagInfo({ detail: 'Finish record, all promises success' });
                                var result;
                                var values;
                                for (var i = 0; i < results.length; i++) {
                                    result = results[i];
                                    if (result.status == 'fulfilled') {
                                        values = result.value;
                                        if (values.hasOwnProperty('type') &&
                                            Object.keys(values).includes('type') &&
                                            values.type == 'screen') {
                                            _this.storeScreenRecord(values);
                                            _this.storeScreenAudio(values);
                                        }
                                        else {
                                            _this.storeCameraRecord(values);
                                            _this.destroyVideoRecorder();
                                        }
                                    }
                                }
                            })
                                .catch(function (error) {
                                vm.bugsnagInfo({ detail: 'Finish record, some promise FAILED ' + error });
                                console.log(error);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Recording Methods
        // Screen
        startScreenRecord: function () {
            return __awaiter(this, void 0, void 0, function () {
                var config;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            config = {
                                width: 1080,
                                maxDuration: 0,
                            };
                            if (this.platform == 'android')
                                document.addEventListener('resume', this.appResumeEvent);
                            return [4 /*yield*/, ScreenRecorder.start()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        appResumeEvent: function () {
            document.removeEventListener('resume', this.appResumeEvent);
            var vm = this;
            setTimeout(function () {
                ScreenRecorder.isRecording().then(function (data) {
                    if (!data.isRecording)
                        vm.screenRecordWarning();
                });
            }, 1000);
        },
        permissionsWarning: function () {
            var execution = this.$refs.execution;
            execution.showWatUxPermissionsModal();
        },
        screenRecordWarning: function () {
            var _this = this;
            var vm = this;
            Swal.fire({
                title: this.$t('tester_required_screen_record'),
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: this.$t('continue_mode'),
                cancelButtonText: this.$t('tester_action_exit'),
                showCancelButton: true,
                allowOutsideClick: false
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (result) {
                        vm.startScreenRecord();
                    }
                    else {
                        vm.closeExecution();
                    }
                    return [2 /*return*/];
                });
            }); });
        },
        errorRecordingWarning: function (vm) {
            var _this = this;
            Swal.fire({
                title: this.$t('tester_test_cant_send_videos'),
                text: this.$t('tester_test_retry'),
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: this.$t('tester_action_ok'),
                showCancelButton: false,
                allowOutsideClick: false
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, vm.$refs.execution.fetchTestInfo()];
                        case 1:
                            _a.sent();
                            vm.closeExecution();
                            return [2 /*return*/];
                    }
                });
            }); });
        },
        stopScreenRecord: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, ScreenRecorder.stop()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        blankVideoRecorderInitialize: function () {
            return __awaiter(this, void 0, void 0, function () {
                var config, timer, timedPromise, intializePromise, event;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            config = {
                                id: 'video-record',
                                stackPosition: 'back',
                                width: 0,
                                height: 0,
                                borderRadius: 0,
                            };
                            timedPromise = new Promise(function (resolve) {
                                timer = setTimeout(function () {
                                    console.log('promise timeout');
                                    var event = new CustomEvent('watux-video-recorder-initialized', {
                                        detail: {
                                            platform: _this.platform,
                                            intialized: false,
                                            response: [],
                                        },
                                    });
                                    window.dispatchEvent(event);
                                }, 5000);
                            });
                            return [4 /*yield*/, VideoRecorder.initialize({
                                    camera: VideoRecorderCamera.FRONT,
                                    previewFrames: [config],
                                }).then(function () {
                                    clearTimeout(timer);
                                    var event = new CustomEvent('watux-video-recorder-initialized', {
                                        detail: {
                                            platform: _this.platform,
                                            intialized: true,
                                            response: [],
                                        },
                                    });
                                    window.dispatchEvent(event);
                                })];
                        case 1:
                            intializePromise = _a.sent();
                            try {
                                return [2 /*return*/, Promise.race([timedPromise, intializePromise]).finally(function () {
                                        clearTimeout(timer);
                                    })];
                            }
                            catch (errors) {
                                clearTimeout(timer);
                                event = new CustomEvent('watux-video-recorder-initialized', {
                                    detail: {
                                        platform: this.platform,
                                        intialized: false,
                                        response: errors,
                                    },
                                });
                                window.dispatchEvent(event);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Camera
        initializeVideoRecorder: function () {
            return __awaiter(this, void 0, void 0, function () {
                var config;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            config = {
                                id: 'video-record',
                                stackPosition: 'back',
                                width: 0,
                                height: 0,
                                borderRadius: 0,
                            };
                            return [4 /*yield*/, VideoRecorder.initialize({
                                    camera: VideoRecorderCamera.FRONT,
                                    previewFrames: [config],
                                })
                                    .then(function () {
                                    return _this.startVideoRecord();
                                })
                                    .catch(function (error) {
                                    console.error(error);
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        startVideoRecord: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, VideoRecorder.startRecording()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        stopVideoRecord: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, VideoRecorder.stopRecording()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        destroyVideoRecorder: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, VideoRecorder.destroy()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
        // Upload records
        storeScreenRecord: function (values) {
            return __awaiter(this, void 0, void 0, function () {
                var blob, data;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(values.videoUrlPath).then(function (result) {
                                return result.blob();
                            })];
                        case 1:
                            blob = _a.sent();
                            this.bugsnagInfo({ detail: 'Store Screen Record file created' });
                            data = {
                                testId: 'capacitor',
                                file: blob,
                                fileType: "screen.mp4",
                                onUploadProgress: function (progressEvent) {
                                    var event = new CustomEvent('watux-progress-event', {
                                        detail: {
                                            source: 'screen',
                                            progressEvent: progressEvent,
                                        },
                                    });
                                    window.dispatchEvent(event);
                                },
                            };
                            watStore
                                .storeTestRecordings(data)
                                .then(function (results) {
                                _this.bugsnagInfo({ detail: 'Store Screen Record UPLOADED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'screen',
                                        path: results.path,
                                    },
                                });
                                window.dispatchEvent(event);
                            })
                                .catch(function (error) {
                                console.log(error);
                                _this.bugsnagInfo({ detail: 'Store Screen Record FAILED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'screen',
                                        error: error,
                                    },
                                });
                                window.dispatchEvent(event);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        storeScreenAudio: function (values) {
            return __awaiter(this, void 0, void 0, function () {
                var blob, data;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!values.hasOwnProperty('audioUrlPath')) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, fetch(values.audioUrlPath).then(function (result) {
                                    return result.blob();
                                })];
                        case 1:
                            blob = _a.sent();
                            this.bugsnagInfo({ detail: 'Store Screen Audio file created' });
                            data = {
                                testId: 'capacitor',
                                file: blob,
                                fileType: "audio.aac",
                                onUploadProgress: function (progressEvent) {
                                    var event = new CustomEvent('watux-progress-event', {
                                        detail: {
                                            source: 'audio',
                                            progressEvent: progressEvent,
                                        },
                                    });
                                    window.dispatchEvent(event);
                                },
                            };
                            watStore
                                .storeTestAudio(data)
                                .then(function (results) {
                                _this.bugsnagInfo({ detail: 'Store Screen Record UPLOADED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'audio',
                                        path: results.path,
                                    },
                                });
                                window.dispatchEvent(event);
                            })
                                .catch(function (error) {
                                console.log(error);
                                _this.bugsnagInfo({ detail: 'Store Screen Record FAILED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'audio',
                                        error: error,
                                    },
                                });
                                window.dispatchEvent(event);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        storeCameraRecord: function (values) {
            return __awaiter(this, void 0, void 0, function () {
                var blob, data;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, fetch(values.videoUrl).then(function (result) {
                                return result.blob();
                            })];
                        case 1:
                            blob = _a.sent();
                            this.bugsnagInfo({ detail: 'Store Camera Record file created' });
                            data = {
                                testId: 'test',
                                file: blob,
                                fileType: "camera.mp4",
                                onUploadProgress: function (progressEvent) {
                                    var event = new CustomEvent('watux-progress-event', {
                                        detail: {
                                            source: 'webcam',
                                            progressEvent: progressEvent,
                                        },
                                    });
                                    window.dispatchEvent(event);
                                },
                            };
                            watStore
                                .storeTestRecordings(data)
                                .then(function (results) {
                                _this.bugsnagInfo({ detail: 'Store Camera Record UPLOADED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'webcam',
                                        path: results.path,
                                    },
                                });
                                window.dispatchEvent(event);
                            })
                                .catch(function (error) {
                                _this.bugsnagInfo({ detail: 'Store Camera Record FAILED' });
                                var event = new CustomEvent('watux-store-record-result', {
                                    detail: {
                                        source: 'webcam',
                                        error: error,
                                    },
                                });
                                window.dispatchEvent(event);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    setup: function (props) {
        var _this = this;
        var route = useRoute();
        var testsStore = inject('testsStore');
        var notifStore = inject('notificationsStore');
        var authStore = inject('authState');
        var url = ref('');
        var iframeUrl = "".concat(process.env.VUE_APP_EXECUTION_IFRAME_URL, "/test/").concat(props.id, "/").concat(authStore.state.authToken, "?stage=questions&isAppWebView=true");
        var iframeUrlFocus = "".concat(process.env.VUE_APP_URL, "/app/test/").concat(props.id, "/show/focus?authToken=").concat(authStore.state.authToken, "&stage=questions");
        var iframeTest = ref(false);
        var webTest = ref(false);
        var browser;
        function loadStartCallBack(event) {
            if (event.url ==
                "".concat(process.env.VUE_APP_EXECUTION_IFRAME_URL, "/goBackToApp.html")) {
                browser.close();
                history.back();
            }
        }
        var openCapacitorSite = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                iframeTest.value = true;
                browser = InAppBrowser.create(iframeUrl, '_blank', 'suppressesIncrementalRendering=yes,hidden=no,location=no,hidenavigationbuttons=yes,fullscreen=yes,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,toolbar=no,toolbarcolor=#00ff00');
                browser.on('loadstart').subscribe(function (e) {
                    loadStartCallBack(e);
                });
                return [2 /*return*/];
            });
        }); };
        var openWebSite = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                webTest.value = true;
                window.open(iframeUrl);
                return [2 /*return*/];
            });
        }); };
        var openWatFocusWebsite = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                webTest.value = true;
                window.open(iframeUrlFocus);
                return [2 /*return*/];
            });
        }); };
        var onBriefViewed = function (showInAppWebview, isEmbeddedTestExternal) {
            if (!Capacitor.isNativePlatform())
                return;
            if (route.name == "focustest") {
                return openWatFocusWebsite();
            }
            if (showInAppWebview) {
                return openCapacitorSite();
            }
            if (isEmbeddedTestExternal) {
                return openWebSite();
            }
        };
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, notifStore.markTestNotificationAsSeen(route.params.id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
        onBeforeRouteLeave(function () {
            testsStore.getTests(0);
        });
        var router = useRouter();
        var goTo = function (url) {
            router.push({ name: url, replace: true });
        };
        return {
            url: url,
            goTo: goTo,
            iframeUrl: iframeUrl,
            iframeTest: iframeTest,
            webTest: webTest,
            onBriefViewed: onBriefViewed,
        };
    },
});

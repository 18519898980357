import { defineComponent } from 'vue';
import { IonButtons } from '@ionic/vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'TestExecutionHeader',
    components: { IonButtons: IonButtons },
    props: { testTitle: String },
    setup: function () {
        var router = useRouter();
        var goTo = function (url) {
            router.push({ name: url, replace: true });
        };
        return {
            goTo: goTo,
        };
    },
});
